<template>
  <section
      id="pricing-plan"
  >
    <b-row class="pricing-card">
      <b-col md="2"></b-col>
      <b-col md="4">
        <b-card
            align="center"
            style="border: 1px solid #20ad95;"
        >
          <b-card-text>
            <strong>Subskrypcja</strong>
          </b-card-text>
          <h3 class="mb-1">ChemMystery</h3>
          <b-card-text style="font-size: 16px; font-weight: bold;">
            3 miesiące
          </b-card-text>

          <!-- annual plan -->
          <div class="annual-plan">
            <div class="plan-price mt-2">
              <span class="pricing-basic-value font-weight-bolder text-primary">{{ partialPrice() / 3 }}zł</span>
              <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/miesiąc</sub>
            </div>
            <div class="plan-price mt-2">
              <sub class="pricing-duration text-body font-medium-1 font-weight-bold">Płatność jednorazowo: {{ partialPrice() }}zł</sub>
            </div>
          </div>
          <hr />
          <div>
            <h5 class="text-left">W każdym miesiącu:</h5>
          </div>
          <!-- plan benefit -->
          <b-list-group class="list-group-circle text-left">
            <b-list-group-item>
              Nagranie (video-lekcja)
            </b-list-group-item>
            <b-list-group-item>
              Quiz
            </b-list-group-item>
            <b-list-group-item>
              Artykuł
            </b-list-group-item>
            <b-list-group-item>
              „Zapytaj egzaminatora OKE”<br />
              <small>zadaj nurtujące Cię pytania egzaminatorowi OKE</small>
            </b-list-group-item>
          </b-list-group>
          <!--/ plan benefit -->
          <hr />
          <b-card-text>
            <b-link :to="{ name: 'shop-item-details', params: { slug: courses.coursePartial.slug }}">
              Więcej informacji
            </b-link>
          </b-card-text>
          <!--/ annual plan -->
          <hr />
          <!-- buttons -->
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              block
              class="mt-2"
              variant="primary"
              @click="addToCartPartial()"
          >
            Dodaj do koszyka
          </b-button>
        </b-card>
      </b-col>
      <b-col md="4">
          <b-card
              align="center"
              style="border: 1px solid #20ad95;"
          >
            <b-card-text>
              <strong>Subskrypcja</strong>
            </b-card-text>
            <h3 class="mb-1">ChemMystery</h3>
            <b-card-text style="font-size: 16px;font-weight: bold;">
              9 miesięcy
            </b-card-text>

            <!-- annual plan -->
            <div class="annual-plan">
              <div class="plan-price mt-2">
                <span class="pricing-basic-value font-weight-bolder text-primary">{{ fullPrice() / 9 }}zł</span>
                <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/miesiąc</sub>
              </div>
              <div class="plan-price mt-2">
                <sub class="pricing-duration text-body font-medium-1 font-weight-bold">Płatność jednorazowo: {{ fullPrice() }}zł</sub>
              </div>
            </div>
            <hr />
            <div>
              <h5 class="text-left">W każdym miesiącu:</h5>
            </div>
            <!-- plan benefit -->
            <b-list-group class="list-group-circle text-left">
              <b-list-group-item>
                Nagranie (video-lekcja)
              </b-list-group-item>
              <b-list-group-item>
                Quiz
              </b-list-group-item>
              <b-list-group-item>
                Artykuł
              </b-list-group-item>
              <b-list-group-item>
                „Zapytaj egzaminatora OKE”<br />
                <small>zadaj nurtujące Cię pytania egzaminatorowi OKE</small>
              </b-list-group-item>
            </b-list-group>
            <!--/ plan benefit -->
            <hr />
            <b-card-text>
              <b-link :to="{ name: 'shop-item-details', params: { slug: courses.courseFull.slug }}">
                Więcej informacji
              </b-link>
            </b-card-text>
            <!--/ annual plan -->
            <hr />
            <!-- buttons -->
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                block
                class="mt-2"
                variant="primary"
                @click="addToCartFull()"
            >
              Dodaj do koszyka
            </b-button>
          </b-card>
        </b-col>
    </b-row>
    <div class="info text-center">
      <p>Dokładny opis ChemMystery znajdziesz <a href="https://chemmaster.pl/nowosc-subskrypcja-chemmystery/" target="_blank">tutaj</a></p>
    </div>
    <div style="position: fixed; bottom: 15px; right: 15px;">
      <audio id="chemmystery-audio" :src="require('@/assets/music/chemmystery.mp3')" autoplay loop></audio>
      <b-button-group>
        <b-button variant="success" onclick="document.getElementById('chemmystery-audio').play()">
          <feather-icon icon="PlayIcon"/>
        </b-button>
        <b-button variant="success" onclick="document.getElementById('chemmystery-audio').pause()">
          <feather-icon icon="PauseIcon" />
        </b-button>
        <b-button variant="success" onclick="document.getElementById('chemmystery-audio').muted=!document.getElementById('chemmystery-audio').muted">
          <feather-icon icon="VolumeXIcon" />
        </b-button>
      </b-button-group>
    </div>
  </section>
</template>
<script>
import {
  BButton,
  BButtonGroup,
  BCard,
  BCardBody,
  BCardText, BCol,
  BImg,
  BLink,
  BListGroup,
  BListGroupItem,
  BRow,
  BTable
} from 'bootstrap-vue'
import {useEcommerceUi} from "@/views/shop/useEcommerce";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardText,
    BLink,
    BImg,
    BListGroup,
    BListGroupItem,
    BButton,
    BButtonGroup,
  },
  data() {
    return {
      courses: [],
      oldBg: null,
    }
  },
  created() {
    this.fetchCourses()
    setTimeout(() => {
      document.querySelector('html[dir] .content.app-content .content-overlay ').style.background = "url('/img/ChemMystery.gif')";
    },300);
  },
  setup() {
    const {handleCartActionClick} = useEcommerceUi();

    return {handleCartActionClick};
  },
  destroyed() {
    document.querySelector('html[dir] .content.app-content .content-overlay ').style.background = 'none';
  },
  methods: {
    fetchCourses() {
      this.$http.get(`${this.$store.state.apiDomain}/api/chemmystery/getCurrentVariants`).then(response => {
        this.courses = response.data;
        if(response.data.hasChemMystery) {
          this.$router.push({name: 'chemmystery-questions'})
        } else {
          setTimeout(function () {
            let audio = document.getElementById('chemmystery-audio');
            audio.volume = 0.4;
            document.getElementById('chemmystery-audio').play();
          }, 500);
        }
      })
    },

    partialPrice() {
      return this.courses.coursePartial.price;
    },
    fullPrice() {
      return this.courses.courseFull.price;
    },
    addToCartPartial() {
      this.handleCartActionClick(this.courses.coursePartial, null)
    },

    addToCartFull() {
      this.handleCartActionClick(this.courses.courseFull, null)
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';
h3{
  font-size: 26px;
  color: #20ad95;
}
html[dir] .content.app-content .content-overlay {
  background-size: 30%;
  opacity: 1;
}

.grid-view {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 2rem;
  &.wishlist-items {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .ecommerce-card {
    overflow: hidden;

    // Item Image
    .item-img {
      padding-top: 0.5rem;
      min-height: 15.85rem;
      display: flex;
      align-items: center;
    }

    // Item content wrapper
    .item-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
    }

    // Hiding list view items
    .shipping,
    .item-company,
    .item-options .item-price {
      display: none;
    }

    .item-options {
      display: flex;
      flex-wrap: wrap;

      .btn-cart,
      .btn-wishlist {
        flex-grow: 1;
        border-radius: 0;
      }
    }

    .item-name {
      margin-top: 0.75rem;
    }

    // Item Description
    .item-description {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-top: 0.2rem;
    }

    .card-body {
      padding: 1rem;
    }
  }
}
@media all and (max-width: 768px){
  .grid-view {
    grid-template-columns: 1fr;
  }
}
</style>
